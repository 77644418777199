<template>
  <div>
    <template>
      <div class="d-flex justify-space-between">
        <v-tabs v-model="tabManagement" align-with-title class="mt-3">
          <v-tabs-slider color="#13ACE0"></v-tabs-slider>
          <v-tab
            v-for="item in tabManagementNames"
            :key="item"
            class="page-title-tab"
          >
            {{ item }}
          </v-tab>
          <v-btn
            v-if="checkPerUser"
            @click="visible = 3"
            small
            class="t-btn--prm mt-3 ml-3"
          >
            グループ新規作成
          </v-btn>
        </v-tabs>
      </div>
    </template>
    <template>
      <v-tabs-items v-model="tabManagement">
        <v-tab-item v-for="item in tabManagementNames" :key="item">
          <ManagementTabUser v-if="tabManagement === 0" />
          <group v-if="tabManagement === 1"></group>
        </v-tab-item>
      </v-tabs-items>
    </template>
    <group-add
      v-if="checkPerUser"
      :visible="visible === 3"
      @close="visible = -1"
    />
  </div>
</template>

<script>
import Group from './group.vue';
import GroupAdd from './GroupAdd.vue';
import ManagementTabUser from './ManagementTabUser.vue';
import { checkPermissionUserCurrent } from '@/utils/permissions';
export default {
  name: 'ContactPerson',
  components: {
    ManagementTabUser,
    Group,
    GroupAdd,
  },
  data() {
    return {
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      visible: -1,
      tabManagement: 0,
      tabManagementNames: [
        this.$t('contract.manager.management'),
        this.$t('contract.manager.group'),
      ],
    };
  },

  computed: {
    email() {
      return !this.$store.getters.user ? false : this.$store.getters.user.email;
    },
  },

  created() {
    // this.$store.dispatch('GetUserInfo');
  },
};
</script>

<style scoped lang="scss">
h1,
p {
  text-align: center;
}
p {
  color: red;
}
.page-title-tab {
  font-size: 16px;
  font-weight: 600;
}
.v-tab {
  font-size: 16px !important;
  color: blue !important;
  &--active {
    color: #006dab !important;
    font-weight: 600;
  }
}
.v-btn {
  height: 32px !important;
}
::v-deep {
  .theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active){
    color: #BDBDBD !important;
  }
  .v-tabs-slider-wrapper {
    .v-tabs-slider {
      background-color: #006dab !important;
      color: #006dab !important;
    }
  }
  .v-btn.v-size--small {
    font-size: 14px;
    font-weight: 500 !important;
  }
}
</style>
