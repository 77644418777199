import { render, staticRenderFns } from "./listTab.vue?vue&type=template&id=529faf72&scoped=true&"
import script from "./listTab.vue?vue&type=script&lang=js&"
export * from "./listTab.vue?vue&type=script&lang=js&"
import style0 from "./listTab.vue?vue&type=style&index=0&id=529faf72&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "529faf72",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
installComponents(component, {VCard,VCheckbox,VTab,VTabItem,VTabs,VTabsItems})
