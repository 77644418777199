<template>
  <div>
    <v-dialog v-model="dialog" @click:outside="closeDialog">
      <v-form ref="form">
        <h1 class="mb-3">{{ $t('contract.manager.CreateNewGroup') }}</h1>
        <div>
          <h6>{{ $t('contract.manager.groupName') }}</h6>
          <v-text-field
            counter="10"
            v-model="name"
            :rules="rulesName"
            style="width: 323px"
            class="mb-5"
          ></v-text-field>
        </div>
        <div class="d-flex justify-end">
          <div class="d-flex">
            <v-btn large class="t-btn--red-dark" @click="closeDialog">
              <v-icon class="mr-2">mdi-close</v-icon>
              {{ $t('contract.manager.close') }}
            </v-btn>
            <v-btn large class="t-btn--prm ml-3" @click="saveAdd">
              {{ $t('contract.manager.save') }}
            </v-btn>
          </div>
        </div>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
export default {
  name: 'GroupAdd',
  data() {
    return {
      groupName: '',
      name: '',
      rulesName: [
        (v) => !!v || this.$t('rules.requiredField'),
        (v) => (v && v.length <= 10) || '10文字以内!',
      ],
    };
  },
  props: {
    visible: Boolean,
  },
  computed: {
    dialog: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },
  methods: {
    ...mapActions(['groupCreate']),
    ...mapMutations(['setAlertSuccess']),

    saveAdd() {
      if (this.$refs.form.validate()) {
        const dataAddGroup = {
          name: this.name,
        };
        this.groupCreate(dataAddGroup);
        this.closeDialog();
      }
    },

    save() {
      this.$emit('close');
    },
    closeDialog() {
      this.$emit('close');
      this.$refs.form.reset();
    },
  },
};
</script>

<style lang="scss" scoped>
body {
  overflow: hidden;
}
h1 {
  font-size: 28px !important;
  font-weight: bold;
  color: #212121 !important;
}
h6 {
  font-size: 10px !important;
  color: #000;
  font-weight: 600 !important;
}
.v-btn {
  height: 32px !important;
  font-size: 14px !important;
}
.t-btn--prm {
  background: #1873d9 !important;
  width: 50px !important;
  min-width: 50px !important;
}
.t-btn--red-dark {
  min-width: 85px !important;
  width: 55px !important;
  i {
    font-size: 16px !important;
  }
}
::v-deep {
  .v-dialog {
    width: auto;
    background-color: white;
    padding: 30px;
    border-radius: 9px;
    font-size: 14px;
    .v-label {
      font-size: 14px;
    }
  }
  .w-50 {
    width: 50%;
  }
  .v-counter {
    font-size: 10px;
    color: var(--text__dark) !important;
    font-weight: 600;
  }
  .sort-item {
    max-width: 100px;
  }
  .cancel-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .v-input {
      max-width: 40px;
      padding: 0;
      * {
        text-align: center;
      }
    }
  }
}
</style>
