<template>
  <div>
    <v-dialog v-model="dialog">
      <v-form ref="form">
        <h1 class="mb-3" style="font-size: 40px">
          {{ $t('contract.manager.GroupEditing') }}
        </h1>
        <div>
          <h6>{{ $t('contract.manager.groupName') }}</h6>
          <v-text-field
            v-model="getUpdate.name"
            :rules="textRules"
            counter="10"
            style="width: 323px"
            class="mb-5"
          ></v-text-field>
        </div>
        <div class="d-flex justify-space-between">
          <v-btn
            large
            class="t-btn--red-dark mr-10"
            style="max-width: 50px !important"
            @click="saveDelete"
          >
            {{ $t('contract.manager.delete') }}
          </v-btn>
          <div class="d-flex justify-end">
            <v-btn large class="t-btn--red-dark" @click="closeDialog">
              <v-icon class="mr-2">mdi-close</v-icon>
              {{ $t('contract.manager.close') }}
            </v-btn>
            <v-btn large class="t-btn--prm ml-3" @click="saveUpdate">
              {{ $t('contract.manager.save') }}
            </v-btn>
          </div>
        </div>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
  name: 'GroupDetail',
  data() {
    return {
      name: '',
      id: null,
      textRules: [
        (v) => !!v || this.$t('rules.requiredField'),
        (v) => (v && v.length <= 10) || "10文字以内!",
      ],
    };
  },
  props: {
    visible: Boolean,
    groupID: Number,
    currentName: String,
  },
  computed: {
    ...mapGetters(['getUpdate']),
    dialog: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },
  methods: {
    ...mapActions(['groupUpdateDetail', 'groupDeleteDetail']),
    ...mapMutations(['setUpdate', 'setAlertSuccess', 'setDete']),
    saveUpdate() {
      if (this.$refs.form.validate()) {
        const dataUpdateGroup = {
          id: this.getUpdate.id,
          name: this.getUpdate.name,
        };
        this.setUpdate(dataUpdateGroup);
        this.groupUpdateDetail();
        this.closeDialog();
      }
    },
    saveDelete() {
      if (this.$refs.form.validate()) {
        const dataDeleteGroup = {
          id: this.getUpdate.id,
          name: this.getUpdate.name,
        };
        this.setDete(dataDeleteGroup);
        this.groupDeleteDetail(dataDeleteGroup);
        this.closeDialog();
      }
    },
    save() {
      this.$emit('close');
    },
    closeDialog() {
      this.$emit('close');
      this.$refs.form.reset();
    },
  },
};
</script>

<style lang="scss" scoped>
body {
  overflow: hidden;
}
h1 {
  font-size: 28px !important;
  font-weight: bold;
  color: #212121 !important;
}
h6 {
  font-size: 10px !important;
  color: #000;
  font-weight: 600 !important;
}
.v-btn {
  height: 32px !important;
  font-size: 14px !important;
}
.t-btn--prm {
  background: #1873d9 !important;
  width: 50px !important;
  min-width: 50px !important;
}

.t-btn--red-dark {
  min-width: 50px !important;
  width: 85px !important;
  i {
    font-size: 16px !important;
  }
}
::v-deep {
  .v-dialog {
    width: auto;
    background-color: white;
    padding: 30px;
    border-radius: 9px;
    font-size: 14px;
    .v-label {
      font-size: 14px;
    }
  }
  .w-50 {
    width: 50%;
  }
  .v-counter {
    font-size: 10px;
    color: var(--text__dark) !important;
    font-weight: 600;
  }
  .sort-item {
    max-width: 100px;
  }
  .cancel-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .v-input {
      max-width: 40px;
      padding: 0;
      * {
        text-align: center;
      }
    }
  }
}
</style>
