var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[[_c('div',{staticClass:"d-flex justify-start ml-10 mt-6"},[_c('v-select',{attrs:{"items":_vm.getStaffGroupList,"item-value":"id","item-text":"name","label":"グループを選択","dense":"","outlined":""},model:{value:(_vm.GrouptId),callback:function ($$v) {_vm.GrouptId=$$v},expression:"GrouptId"}}),(_vm.checkPerUser)?_c('v-btn',{staticClass:"btn-crm-primary mx-2",attrs:{"outlined":"","small":"","color":"var(--text_gray)","hide-details":""},on:{"click":_vm.addGroup}},[_vm._v("チェックした担当者を選択したグループに追加")]):_vm._e(),(_vm.checkPerUser)?_c('v-btn',{staticClass:"btn-crm-red",attrs:{"outlined":"","small":"","color":"var(--text_gray)","hide-details":""},on:{"click":_vm.deleteGroup}},[_vm._v("チェックした担当者を選択したグループから削除")]):_vm._e()],1),_c('v-col',{attrs:{"cols":9}},[_c('div',{staticClass:"ml-7"},[_c('v-card',{staticClass:"mt-3"},[_c('Table',{ref:"table",attrs:{"attr":{
              dense: true,
              'no-data-text': _vm.$t('rules.noData'),
              'item-key': 'id',
            },"itemsPerPage":10,"itemsPerPageOptions":[10, 40, 50, 100, 200, 500],"headers":_vm.header,"items":_vm.getStaffListSalesManagement,"disableSort":true,"showSelect":true},on:{"setSelected":function (value) { return _vm.enterSelect(value); }},scopedSlots:_vm._u([{key:"item.avatar",fn:function(ref){
            var item = ref.item;
return [_c('v-avatar',{attrs:{"size":"38px"}},[_c('img',{attrs:{"src":item.profileImgUrl}})])]}},{key:"item.group",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.groups.map(function (c) { return c.name; }).join(',')))])]}},{key:"item.lastLoginAtt",fn:function(ref){
            var item = ref.item;
return [(item.lastLoginAt === null)?_c('span'):_c('span',[_vm._v(_vm._s(_vm.formatDate(item.lastLoginAt)))])]}}],null,true)})],1)],1)])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }