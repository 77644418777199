var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ml-10 mr-10 mt-3"},[_c('v-card',{staticClass:"mb-4"},[_c('Table',{ref:"table",attrs:{"attr":{
        dense: true,
        'no-data-text': _vm.$t('rules.noData'),
        'item-key': 'id',
      },"itemsPerPage":30,"itemsPerPageOptions":[30, 40, 50, 100, 200, 500],"disableSort":true,"headers":_vm.header,"items":_vm.getStaffListSales},scopedSlots:_vm._u([{key:"item.groupsName",fn:function(ref){
      var item = ref.item;
return [_c('div',{attrs:{"id":"divOverflow"}},[_c('span',[_vm._v(_vm._s(item.groups.map(function (c) { return c.name; }).join(',')))])])]}},{key:"item.mentionName",fn:function(ref){
      var item = ref.item;
return [_c('div',[_c('span-clipboard',{attrs:{"text":("@" + (item.mentionName))}})],1)]}},{key:"item.avatar",fn:function(ref){
      var item = ref.item;
return [_c('v-avatar',{attrs:{"size":"38px"}},[_c('img',{attrs:{"src":item.profileImgUrl}})])]}},{key:"item.actionPlannedAt",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.nextAction && _vm.formatDate(item.nextAction.actionPlannedAt))+" ")]}},{key:"item.lastLoginAtt",fn:function(ref){
      var item = ref.item;
return [(item.lastLoginAt === null)?_c('span'):_c('span',[_vm._v(_vm._s(_vm.formatDate(item.lastLoginAt)))])]}},{key:"item.crmComputed.clientCount",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.crmComputed ? item.crmComputed.clientCount : '')+" ")]}},{key:"item.crmComputed.contractingCount",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.crmComputed ? item.crmComputed.contractingCount : '')+" ")]}},{key:"item.crmComputed.goodCount",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.crmComputed ? item.crmComputed.goodCount : '')+" ")]}},{key:"item.crmComputed.coolCount",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.crmComputed ? item.crmComputed.coolCount : '')+" ")]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }