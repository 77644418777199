<template>
  <div class="ml-10 mt-3 col-11">
    <v-card>
      <Table
        :attr="{
          dense: true,
          'no-data-text': $t('rules.noData'),
          'item-key': 'id',
        }"
        ref="table"
        :itemsPerPage="30"
        :itemsPerPageOptions="[30, 40, 50, 100, 200, 500]"
        :headers="header"
        :items="getStaffGroupList"
      >
        <template v-slot:[`item.NumberOfPeople`]="{ item }">
          {{ item.staffs.length }}
        </template>

        <template v-slot:[`item.Member`]="{ item }">
          <div
            class="d-flex align-center"
            v-if="item.staffs && item.staffs.length > 0"
          >
            <div
              class="d-flex align-center"
              v-for="(el, idx) in item.staffs"
              :key="idx"
            >
              <template v-if="idx < 25">
                <v-avatar size="38px">
                  <img :src="el.profileImgUrl" />
                </v-avatar>
                <span>{{ el.name }}</span>
              </template>
            </div>
            <span v-if="item.staffs.length > 25"
              >...(他{{ item.staffs.length - 5 }}人）</span
            >
          </div>
        </template>
      </Table>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Table from '@/components/Table/index.vue';

export default {
  name: 'Group',
  components: {
    Table,
  },
  data() {
    return {
      visible: -1,
      member: [],
      header: [
        {
          text: this.$t('contract.manager.groupName'),
          value: 'name',
          width: '150px',
        },
        {
          text: this.$t('contract.manager.numOfPersons'),
          value: 'NumberOfPeople',
          width: '100px',
        },
        {
          text: this.$t('contract.manager.member'),
          value: 'Member',
          align: 'start',
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['getStaffGroupList']),
    email() {
      return !this.$store.getters.user ? false : this.$store.getters.user.email;
    },
  },
  mounted() {
    this.groupList();
  },
  methods: {
    ...mapActions(['groupList']),
    ...mapMutations(['setStaffGroupList']),
  },
};
</script>

<style scoped lang="scss">
div#divOverflow {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
h1,
p {
  text-align: center;
}
p {
  color: red;
}
.v-data-table::v-deep {
  th,
  .v-select__selection {
    font-size: 16px !important;
    color: #424242 !important;
    font-weight: 400 !important;
  }
  tbody {
    tr {
      td {
        color: #424242 !important;
        font-size: 16px !important;
        white-space: nowrap;
        font-weight: 400 !important;
      }
    }
  }
  .v-data-footer {
    color: #000 !important;
    font-weight: 500 !important;
    font-size: 13px !important;
    .theme--light.v-input {
      .v-input__control {
        .v-select__selection--comma {
          color: #000 !important;
          font-weight: 500 !important;
          font-size: 13px !important;
        }
      }
    }
    .v-data-footer__pagination {
      display: none !important;
    }
    .v-input__append-inner {
      .v-icon__svg {
        color: #333333 !important;
      }
    }
  }
}
</style>
