<template>
  <div class="ml-10 mt-3 col-11">
    <v-card>
      <Table
        :attr="{
          dense: true,
          'no-data-text': $t('rules.noData'),
          'item-key': 'id',
        }"
        ref="table"
        :itemsPerPage="10"
        :itemsPerPageOptions="[10, 40, 50, 100, 200, 500]"
        :headers="header"
        :items="getStaffGroupList"
        :disableSort="true"
        @setSelected="value => enterSelect(value)"
      >
        <template v-slot:[`item.NumberOfPeople`]="{ item }">
          {{ item.staffs.length }}
        </template>
        <template v-slot:[`item.Member`]="{ item }">
          <div>
            <div v-if="item.staffs.length > 5">
              <div class="d-flex align-center">
                <div
                  class="d-flex align-center"
                  v-for="(el, idx) in item.staffs"
                  :key="idx"
                >
                  <template v-if="idx < 5">
                    <v-avatar size="27px" class="ml-3 mr-1">
                      <img :src="el.profileImgUrl" />
                    </v-avatar>
                    <span>{{ el.name }}</span>
                  </template>
                </div>
                <span class="pl-2">...(他{{ item.staffs.length - 5 }}人）</span>
              </div>
            </div>
            <div v-else>
              <div class="d-flex align-center">
                <div
                  class="d-flex align-center"
                  v-for="(el, idx) in item.staffs"
                  :key="idx"
                >
                  <template v-if="idx < 5">
                    <v-avatar size="27px" class="ml-3 mr-1">
                      <img :src="el.profileImgUrl" />
                    </v-avatar>
                    <span>{{ el.name }}</span>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-slot:[`item.edit`]="{ item }">
          <v-btn icon fab color="#13ace0" :disabled="!checkPerUser">
            <v-icon x-large @click="checkPerUser ? showEdit(item) : ''">
              mdi-pencil-circle
            </v-icon>
          </v-btn>
        </template>
      </Table>
    </v-card>
    <group-detail :visible="visible === 1" @close="visible = -1"></group-detail>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import GroupDetail from './GroupDetail.vue';
import { checkPermissionUserCurrent } from '@/utils/permissions';
import Table from '@/components/Table/index.vue';

export default {
  components: { GroupDetail, Table },
  name: 'Group',
  data() {
    return {
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      visible: -1,
      currentPlanID: 0,
      currentNameID: '',
      member: [],
      dataTest: [],
      header: [
        {
          text: this.$t('contract.manager.groupName'),
          value: 'name',
          width: '150px',
        },
        {
          text: this.$t('contract.manager.numOfPersons'),
          value: 'NumberOfPeople',
          width: '100px',
        },
        {
          text: this.$t('contract.manager.member'),
          value: 'Member',
          align: 'start',
        },
        { text: 'グループ編集', value: 'edit', width: '130px', align: 'right' },
      ],
    };
  },
  computed: {
    ...mapGetters(['getStaffGroupList']),
  },
  mounted() {
    this.groupList();
  },
  methods: {
    ...mapActions(['groupList']),
    ...mapMutations(['setStaffGroupList', 'setUpdate']),
    showEdit(item) {
      const dataUpdateGroup = {
        id: item.id,
        name: item.name,
      };
      this.setUpdate(dataUpdateGroup);
      this.visible = 1;
    },
  },
};
</script>

<style scoped lang="scss">
div#divOverflow {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
h1,
p {
  text-align: center;
}
p {
  color: red;
}
.v-data-table::v-deep {
  th,
  .v-select__selection {
    font-size: 16px !important;
    color: #424242 !important;
    font-weight: 400 !important;
  }
  tbody {
    tr {
      td {
        color: #424242 !important;
        font-size: 16px !important;
        white-space: nowrap;
        font-weight: 400 !important;
      }
    }
  }
  .v-data-footer {
    color: #000 !important;
    font-weight: 500 !important;
    font-size: 13px !important;
    .theme--light.v-input {
      .v-input__control {
        .v-select__selection--comma {
          color: #000 !important;
          font-weight: 500 !important;
          font-size: 13px !important;
        }
      }
    }
    .v-input__append-inner {
      .v-icon__svg {
        color: #333333 !important;
      }
    }
    .v-data-footer__pagination {
      display: none !important;
    }
  }
}
</style>
