<template>
  <span>
    <slot>
      {{text}} <v-icon small @click="copy">mdi-content-copy</v-icon>
    </slot>
  </span>
</template>

<script>
export default {
  props: {
    text: String,
  },
  methods: {
    copy() {
      navigator.clipboard.writeText(this.text);
      this.$store.commit('setAlertSuccess', 'コピーしました')
    }
  }
}
</script>