var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ml-10 mt-3 col-11"},[_c('v-card',[_c('Table',{ref:"table",attrs:{"attr":{
        dense: true,
        'no-data-text': _vm.$t('rules.noData'),
        'item-key': 'id',
      },"itemsPerPage":10,"itemsPerPageOptions":[10, 40, 50, 100, 200, 500],"headers":_vm.header,"items":_vm.getStaffGroupList,"disableSort":true},on:{"setSelected":function (value) { return _vm.enterSelect(value); }},scopedSlots:_vm._u([{key:"item.NumberOfPeople",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.staffs.length)+" ")]}},{key:"item.Member",fn:function(ref){
      var item = ref.item;
return [_c('div',[(item.staffs.length > 5)?_c('div',[_c('div',{staticClass:"d-flex align-center"},[_vm._l((item.staffs),function(el,idx){return _c('div',{key:idx,staticClass:"d-flex align-center"},[(idx < 5)?[_c('v-avatar',{staticClass:"ml-3 mr-1",attrs:{"size":"27px"}},[_c('img',{attrs:{"src":el.profileImgUrl}})]),_c('span',[_vm._v(_vm._s(el.name))])]:_vm._e()],2)}),_c('span',{staticClass:"pl-2"},[_vm._v("...(他"+_vm._s(item.staffs.length - 5)+"人）")])],2)]):_c('div',[_c('div',{staticClass:"d-flex align-center"},_vm._l((item.staffs),function(el,idx){return _c('div',{key:idx,staticClass:"d-flex align-center"},[(idx < 5)?[_c('v-avatar',{staticClass:"ml-3 mr-1",attrs:{"size":"27px"}},[_c('img',{attrs:{"src":el.profileImgUrl}})]),_c('span',[_vm._v(_vm._s(el.name))])]:_vm._e()],2)}),0)])])]}},{key:"item.edit",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","fab":"","color":"#13ace0","disabled":!_vm.checkPerUser}},[_c('v-icon',{attrs:{"x-large":""},on:{"click":function($event){_vm.checkPerUser ? _vm.showEdit(item) : ''}}},[_vm._v(" mdi-pencil-circle ")])],1)]}}],null,true)})],1),_c('group-detail',{attrs:{"visible":_vm.visible === 1},on:{"close":function($event){_vm.visible = -1}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }