<template>
  <div class="ml-10 mr-10 mt-3">
    <v-card class="mb-4">
      <Table
        :attr="{
          dense: true,
          'no-data-text': $t('rules.noData'),
          'item-key': 'id',
        }"
        ref="table"
        :itemsPerPage="30"
        :itemsPerPageOptions="[30, 40, 50, 100, 200, 500]"
        :disableSort="true"
        :headers="header"
        :items="getStaffListSales"
      >
        <template v-slot:[`item.groupsName`]="{ item }">
          <div id="divOverflow">
            <span>{{ item.groups.map(c => c.name).join(',') }}</span>
          </div>
        </template>
        <template v-slot:[`item.mentionName`]="{ item }">
          <div><span-clipboard :text="`@${item.mentionName}`" /></div>
        </template>
        <template v-slot:[`item.avatar`]="{ item }">
          <v-avatar size="38px">
            <img :src="item.profileImgUrl" />
          </v-avatar>
        </template>
        <template v-slot:[`item.actionPlannedAt`]="{ item }">
          {{ item.nextAction && formatDate(item.nextAction.actionPlannedAt) }}
        </template>
        <template v-slot:[`item.lastLoginAtt`]="{ item }">
          <span v-if="item.lastLoginAt === null"></span>
          <span v-else>{{ formatDate(item.lastLoginAt) }}</span>
        </template>
        <template v-slot:[`item.crmComputed.clientCount`]="{ item }">
          {{ item.crmComputed ? item.crmComputed.clientCount : '' }}
        </template>
        <template v-slot:[`item.crmComputed.contractingCount`]="{ item }">
          {{ item.crmComputed ? item.crmComputed.contractingCount : '' }}
        </template>
        <template v-slot:[`item.crmComputed.goodCount`]="{ item }">
          {{ item.crmComputed ? item.crmComputed.goodCount : '' }}
        </template>
        <template v-slot:[`item.crmComputed.coolCount`]="{ item }">
          {{ item.crmComputed ? item.crmComputed.coolCount : '' }}
        </template>
      </Table>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import moment from 'moment';
import SpanClipboard from '../../../components/shared/SpanClipboard.vue';
import Table from '@/components/Table/index.vue';

export default {
  name: 'SalesRepresentative',
  components: { SpanClipboard, Table },
  data() {
    return {
      dataTest: [],
      header: [
        { text: '', value: 'avatar', width: '40px', align: 'center' },
        {
          text: this.$t('contract.manager.salesRepresentative'),
          value: 'name',
          width: '120px',
        },
        {
          text: this.$t('contract.manager.mentionName'),
          value: 'mentionName',
          width: '120px',
        },
        {
          text: this.$t('contract.manager.group'),
          value: 'groupsName',
          width: '100px',
        },
        {
          text: this.$t('contract.manager.DuringContractProcedure'),
          value: 'crmComputed.clientCount',
          width: '100px',
        },
        {
          text: this.$t('contract.manager.Good'),
          value: 'crmComputed.contractingCount',
          width: '120px',
        },
        {
          text: this.$t('contract.manager.Cool'),
          value: 'crmComputed.goodCount',
          width: '100px',
        },
        {
          text: this.$t('contract.manager.Dead'),
          value: 'crmComputed.coolCount',
          width: '100px',
        },
        {
          text: this.$t('contract.manager.NextActivitySchedule'),
          value: 'nextAction.action',
          width: '370px',
        },
        {
          text: this.$t('contract.manager.NextActivityScheduledTime'),
          value: 'actionPlannedAt',
          width: '160px',
        },
        { text: this.$t('contract.manager.LastLogin'), value: 'lastLoginAtt' },
        {
          text: this.$t('contract.manager.UserID'),
          value: 'id',
          width: '120px',
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['getStaffListSales']),
    email() {
      return !this.$store.getters.user ? false : this.$store.getters.user.email;
    },
  },
  mounted() {
    this.salesRepresentativeList();
  },
  methods: {
    ...mapActions(['salesRepresentativeList']),
    ...mapMutations(['setStaffListSales']),
    formatDate(date) {
      return moment(date).format('YYYY/MM/DD HH:mm');
    },
  },
};
</script>

<style scoped lang="scss">
div#divOverflow {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

h1,
p {
  text-align: center;
}
p {
  color: red;
}
.v-data-table::v-deep {
  th,
  .v-select__selection {
    font-size: 14px !important;
    color: #424242 !important;
    font-weight: 400 !important;
  }
  tbody {
    tr {
      td {
        color: #424242 !important;
        font-size: 14px !important;
        white-space: nowrap;
        font-weight: 400 !important;
      }
    }
  }
  .v-data-footer {
    color: #000 !important;
    font-weight: 500 !important;
    font-size: 11px !important;
    .theme--light.v-input {
      .v-input__control {
        .v-select__selection--comma {
          color: #000 !important;
          font-weight: 500 !important;
          font-size: 11px !important;
        }
      }
    }
    .v-data-footer__pagination {
      display: none !important;
    }
  }
}
::v-deep {
  .v-input__append-inner {
    .v-icon__svg {
      color: #333333 !important;
    }
  }
}
</style>
