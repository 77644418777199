var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ml-10 mt-3 col-11"},[_c('v-card',[_c('Table',{ref:"table",attrs:{"attr":{
        dense: true,
        'no-data-text': _vm.$t('rules.noData'),
        'item-key': 'id',
      },"itemsPerPage":30,"itemsPerPageOptions":[30, 40, 50, 100, 200, 500],"headers":_vm.header,"items":_vm.getStaffGroupList},scopedSlots:_vm._u([{key:"item.NumberOfPeople",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.staffs.length)+" ")]}},{key:"item.Member",fn:function(ref){
      var item = ref.item;
return [(item.staffs && item.staffs.length > 0)?_c('div',{staticClass:"d-flex align-center"},[_vm._l((item.staffs),function(el,idx){return _c('div',{key:idx,staticClass:"d-flex align-center"},[(idx < 25)?[_c('v-avatar',{attrs:{"size":"38px"}},[_c('img',{attrs:{"src":el.profileImgUrl}})]),_c('span',[_vm._v(_vm._s(el.name))])]:_vm._e()],2)}),(item.staffs.length > 25)?_c('span',[_vm._v("...(他"+_vm._s(item.staffs.length - 5)+"人）")]):_vm._e()],2):_vm._e()]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }