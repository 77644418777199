<template>
  <div>
    <v-card-title v-once class="mt-n5 page-title-sub card-title">
      <v-row style="background-color: #f8f8f8">
        <v-col :cols="10" class="pb-0">
          <h1 class="mx-6 my-4 page-title-list" style="text-align: left">
            {{ $t('contract.manager.management') }}
          </h1>
        </v-col>
      </v-row>
    </v-card-title>
    <template>
      <v-tabs v-model="tab" align-with-title>
        <v-tabs-slider color="#13ACE0"></v-tabs-slider>
        <!-- <v-tab
          class="page-title-tab"
          v-for="(item, i) in items"
          :key="i"
          :to="item.to"
          @click="curentTab = i"
          >{{ item.title }}
        </v-tab> -->

        <v-tab v-for="item in tabNames" :key="item">
          {{ item }}
        </v-tab>
        <!-- </v-tabs> -->
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="item in tabNames" :key="item">
            <list-tab v-if="tab === 0" />
            <ContactPerson v-if="tab === 1" />
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
      <slot name="default" />
    </template>
  </div>
</template>

<script>
import ContactPerson from './management/ContactPerson.vue';
import ListTab from './list/listTab.vue';

export default {
  name: 'UserTab',
  components: { ContactPerson, ListTab },
  data() {
    return {
      tab: 0,
      tabNames: [
        this.$t('contract.manager.list'),
        this.$t('contract.manager.manager'),
      ],
      // tab: null,
      items: [
        {
          title: '一覧',
          to: '/manager',
        },
        {
          title: '担当顧客',
          to: '/manager/manage',
        },
      ],
    };
  },
  computed: {
    email() {
      return !this.$store.getters.user ? false : this.$store.getters.user.email;
    },
  },
  created() {
    // this.$store.dispatch('GetUserInfo');
  },
};
</script>

<style lang="scss" scoped>
.v-tab--active {
  background-color: #ffffff !important;
  color: #13ace0 !important;
}
.v-tab--active:hover {
  background-color: #ceeffb !important;
  color: #13ace0 !important;
}
.v-tab {
  background-color: #ffffff;
  color: #aaaaaa;
  font-size: 16px !important;
}
.v-tab:hover {
  background-color: #eeeeee;
  color: #aaaaaa;
}
.btn-icon {
  background-color: #13ace0;
  margin-top: 12px;
}
.v-label {
  font-size: 12px !important;
}
h1 {
  font-size: 20px !important;
  font-weight: bold;
  color: #000000 !important;
}
::v-deep {
  .v-input__append-inner {
    .v-icon__svg {
      color: #333333 !important
    }
  }
}
</style>
