<template>
  <div>
    <template>
      <div class="d-flex justify-start ml-10 mt-6">
        <!-- edit -->
        <v-select
          :items="getStaffGroupList"
          v-model="GrouptId"
          item-value="id"
          item-text="name"
          label="グループを選択"
          dense
          outlined
        ></v-select>
        <!-- end edit  -->
        <v-btn
          v-if="checkPerUser"
          outlined
          small
          color="var(--text_gray)"
          class="btn-crm-primary mx-2"
          hide-details
          @click="addGroup"
          >チェックした担当者を選択したグループに追加</v-btn
        >
        <v-btn
          v-if="checkPerUser"
          outlined
          small
          color="var(--text_gray)"
          class="btn-crm-red"
          hide-details
          @click="deleteGroup"
          >チェックした担当者を選択したグループから削除</v-btn
        >
      </div>
      <v-col :cols="9">
        <div class="ml-7">
          <v-card class="mt-3">
            <Table
              :attr="{
                dense: true,
                'no-data-text': $t('rules.noData'),
                'item-key': 'id',
              }"
              ref="table"
              :itemsPerPage="10"
              :itemsPerPageOptions="[10, 40, 50, 100, 200, 500]"
              :headers="header"
              :items="getStaffListSalesManagement"
              :disableSort="true"
              :showSelect="true"
              @setSelected="value => enterSelect(value)"
            >
              <template v-slot:[`item.avatar`]="{ item }">
                <v-avatar size="38px">
                  <img :src="item.profileImgUrl" />
                </v-avatar>
              </template>
              <template v-slot:[`item.group`]="{ item }">
                <span>{{ item.groups.map(c => c.name).join(',') }}</span>
              </template>
              <template v-slot:[`item.lastLoginAtt`]="{ item }">
                <span v-if="item.lastLoginAt === null"></span>
                <span v-else>{{ formatDate(item.lastLoginAt) }}</span>
              </template>
            </Table>
          </v-card>
        </div>
      </v-col>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import moment from 'moment';
import { checkPermissionUserCurrent } from '@/utils/permissions';
import Table from '@/components/Table/index.vue';

export default {
  name: 'ManagementTabUser',
  components: {
    Table,
  },
  data() {
    return {
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      GrouptId: null,
      id: null,
      staffGroupId: null,
      staffIds: [],
      itemSelect: [1, 2, 3, 4, 5, 6],
      dataSelect: 1,
      SelectAGroup: this.$t('contract.manager.SelectAGroup'),
      dataTest: [],
      header: [
        { text: '', value: 'avatar', width: '40px', align: 'center' },
        { text: this.$t('contract.manager.management'), value: 'name' },
        { text: this.$t('contract.manager.group'), value: 'group' },
        { text: this.$t('contract.manager.LastLogin'), value: 'lastLoginAtt' },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'getStaffListSalesManagement',
      'getStaffGroupList',
      'getDataManagement',
    ]),
    email() {
      return !this.$store.getters.user ? false : this.$store.getters.user.email;
    },
  },
  mounted() {
    this.salesRepresentativeList();
    this.groupList();
  },
  methods: {
    ...mapActions([
      'salesRepresentativeList',
      'groupList',
      'managementTabUser',
      'managementTabUserRemove',
    ]),
    ...mapMutations([
      'setStaffListSales',
      'setStaffGroupList',
      'setAlertSuccess',
      'setDataRemoveManagement',
      'setAlertError',
      'setDataManagement',
    ]),
    formatDate(date) {
      return moment(date).format('YYYY/MM/DD HH:mm');
    },
    addGroup() {
      const dataManagement = {
        staffGroupId: this.GrouptId,
        staffIds: this.staffIds,
      };
      this.managementTabUser(dataManagement);
    },
    deleteGroup() {
      const dataRemoveManagement = {
        groupId: this.GrouptId,
        staffIds: this.staffIds,
      };
      this.managementTabUserRemove(dataRemoveManagement);
    },
    enterSelect(values) {
      this.staffIds = [];
      for (let i = 0; i < values.length; i++) {
        this.staffIds.push(values[i].id);
      }
    },
  },
};
</script>

<style scoped lang="scss">
body {
  overflow-scrolling: unset;
}
h1,
p {
  text-align: center;
}
p {
  color: red;
}
.v-btn {
  height: 32px !important;
}
.v-input {
  max-width: 150px !important;
  height: 30px !important;
}
.v-data-table::v-deep {
  th,
  .v-select__selection {
    font-size: 16px !important;
    color: #424242 !important;
    font-weight: 400 !important;
  }
  tbody {
    tr {
      td {
        color: #424242 !important;
        font-size: 16px !important;
        white-space: nowrap;
        font-weight: 400 !important;
      }
    }
  }
  .v-data-footer {
    color: #000 !important;
    font-weight: 500 !important;
    font-size: 13px !important;
    .theme--light.v-input {
      .v-input__control {
        .v-select__selection--comma {
          color: #000 !important;
          font-weight: 500 !important;
          font-size: 13px !important;
        }
      }
    }
    .v-data-footer__pagination {
      display: none !important;
    }
  }
}
::v-deep {
  // .secWidth {
  .v-text-field--outlined.v-input--dense.v-text-field--outlined
    > .v-input__control
    > .v-input__slot {
    height: 30px !important;
  }
  // }

  .v-text-field {
    .v-input__control {
      fieldset {
        height: 35px !important;
      }
    }
    .v-select__selection--comma {
      font-size: 10px !important;
      color: #000000 !important;
      font-weight: 500 !important;
    }
    .v-select__slot {
      height: 30px !important;
      .v-label {
        top: 5px !important;
        // margin: auto 0 !important;
        &--active {
          color: #000000 !important;
          font-size: 10px !important;
          font-weight: 500 !important;
        }
      }
    }
    .v-icon__svg {
      color: #333333 !important;
    }
    .v-input__append-inner {
      // margin-top: 0 !important;
      margin: auto 0 !important;
    }
    .v-text-field--outlined.v-input--dense .v-label {
      top: 0 !important;
    }
  }
  .v-input__append-inner {
    .v-icon__svg {
      color: #333333 !important;
    }
  }
}
</style>
