<template>
  <div>
    <template>
      <div class="d-flex flex-row">
        <v-card>
          <v-tabs v-model="tabManagement" align-with-title class="mt-3">
            <v-tab
              v-for="item in tabManagementNames"
              :key="item"
              class="page-title-tab"
            >
              {{ item }}
            </v-tab>
          </v-tabs>
        </v-card>
        <v-card flat>
          <div v-if="tabManagement === 0">
            <v-checkbox
              class="mt-5 ml-3"
              v-model="showDisabled"
              :label="this.$t('contract.manager.ShowDisabledUsers')"
            ></v-checkbox>
          </div>
        </v-card>
      </div>
    </template>
    <template>
      <v-tabs-items v-model="tabManagement">
        <v-tab-item v-for="item in tabManagementNames" :key="item">
          <group v-if="tabManagement === 1" />
          <SalesRepresentative v-if="tabManagement === 0" />
        </v-tab-item>
      </v-tabs-items>
    </template>
  </div>
</template>

<script>
import SalesRepresentative from './SalesRepresentative.vue';
import Group from './Group';
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  name: 'ListTab',
  components: {
    Group,
    SalesRepresentative,
  },
  data() {
    return {
      visible: -1,
      tabManagement: 0,
      tabManagementNames: [
        this.$t('contract.manager.salesRepresentative'),
        this.$t('contract.manager.group'),
      ],
    };
  },
  computed: {
    ...mapGetters(['getShowDisabled']),
    showDisabled: {
      get() {
        return this.getShowDisabled;
      },
      set(value) {
        this.setLoadingOverlayShow();
        this.setShowDisabled(value);
        this.salesRepresentativeList();
        setTimeout(() => {
          this.setLoadingOverlayHide();
        }, 1000);
      },
    },
    email() {
      return !this.$store.getters.user ? false : this.$store.getters.user.email;
    },
  },
  mounted() {
    this.salesRepresentativeList();
  },
  methods: {
    ...mapActions(['salesRepresentativeList']),
    ...mapMutations([
      'setShowDisabled',
      'setLoadingOverlayShow',
      'setLoadingOverlayHide',
    ]),
  },
};
</script>

<style scoped lang="scss">
h1,
p {
  text-align: center;
}
p {
  color: red;
}
.page-title-tab {
  font-size: 16px;
  font-weight: 600;
}
.v-tab {
  font-size: 16px !important;
  &--active {
    color: #006dab !important;
    font-weight: 600;
  }
}

::v-deep {
  .v-tabs-slider-wrapper {
    .v-tabs-slider {
      color: #006dab !important;
    }
  }
  .theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
    color: #bdbdbd !important;
  }
  .v-input--checkbox {
    .v-label {
      color: #666666 !important;
      font-size: 16px !important;
      font-weight: 400 !important;
      font-size: 16px !important;
    }
  }
}
</style>
